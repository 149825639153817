<template>
  <SectionContainer
    bg-color="02"
    v-if="!isMobile"
  >
    <div class="c-confirmOrder">
      <Typography
        class="-fontBold h-mb-0"
        v-text="$t('widgets.checkout.suggestedProducts.confirmOrder.title')"
        variant="subtitle2"
      />
      <Typography
        class="h-mb-20"
        v-text="$t('widgets.checkout.suggestedProducts.confirmOrder.subTitle')"
        variant="body2"
      />
      <div class="c-confirmOrder__totalSection">
        <span class="c-confirmOrder__totalText">
          {{ $t('widgets.checkout.suggestedProducts.confirmOrder.total') }}
        </span>
        <span
          class="c-confirmOrder__subTotal"
          v-text="formattedSubtotalDiscounted || subTotal"
        />
      </div>
      <BaseButton
        variant="solid"
        color="secondary"
        :btn-label="$t('widgets.checkout.suggestedProducts.confirmOrder.buttonText')"
        :to="$i18nRoute({name: PAYMENT})"
      />
    </div>
  </SectionContainer>
</template>

<script setup>
import { computed, inject } from 'vue';
import { useStore } from 'vuex';

import BaseButton from '@/components/common/BaseButton.vue';

const store = useStore();
const { PAYMENT } = inject('routeNames');
const formattedSubtotalDiscounted = computed(() => store.getters['cart/getFormattedSubTotalDiscounted']);
const subTotal = computed(() => store.getters['cart/getFormattedSubTotal']);
const isMobile = inject('isMobile');

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-confirmOrder {
  @include mx.d-flex(center, center, true);

  padding-top: 3em;
  padding-bottom: 3em;

  &__totalSection {
    @include mx.d-flex(false, false, true);
  }

  &__totalText {
    @include mx.use-color('primary');

    text-align: center;
  }

  &__subTotal {
    padding: 0.5rem 0;

    font-weight: bold;
    @include mx.font-size(23px);
  }
}
</style>
