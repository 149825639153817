<template>
  <Tabs
    group-name="checkout-group"
    :initial-tab="initialTab"
    @change="setSelectedTab"
  >
    <Tab
      v-if="defaultDeliveryType && selectedRestaurant.id && selectedRestaurant.delivery === 1"
      :name="$t('widgets.checkout.homeDelivery.title')"
    >
      <slot />
    </Tab>
    <Tab
      v-if="defaultTakeawayType && selectedRestaurant.id && selectedRestaurant.takeaway === 1"
      :name="$t('widgets.checkout.takeAwayText')"
    >
      <slot />
    </Tab>
    <Tab
      v-if="defaultCateringType && selectedRestaurant.id && selectedRestaurant.catering === 1"
      :name="$t('widgets.checkout.cateringText')"
    >
      <slot />
    </Tab>
  </Tabs>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import Tab from '@/components/common/Tabs/Tab.vue';
import Tabs from '@/components/common/Tabs/Tabs.vue';

const store = useStore();
const isAuthenticated = computed(() => store.state.auth.isAuthenticated);
const initialTab = computed(() => store.state.order.orderData.delivery_type);
const selectedRestaurant = computed(() => store.state.restaurant.selectedRestaurant);
const defaultDeliveryType = computed(() => store.getters['reusable/getSetting']('delivery_type_delivery'));
const defaultTakeawayType = computed(() => store.getters['reusable/getSetting']('delivery_type_takeaway'));
const defaultCateringType = computed(() => store.getters['reusable/getSetting']('delivery_type_shop'));
const setSelectedTab = evt => {
  store.dispatch('order/setOrderData', {
    delivery_type: evt.target.value,
    delivery_date: new Date().toISOString().slice(0, 10),
    delivery_time: '',
  });
  store.dispatch('payment/getPaymentMethods', evt.target.value);
  store.dispatch(`cart/${isAuthenticated.value
    ? 'updateCartItems' : 'updateTempCartItems'}`, { delivery_type: evt.target.value });
};

</script>
