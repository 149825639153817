<template>
  <BaseContainer v-if="suggestedProducts?.length">
    <div class="c-suggestedProducts">
      <ResponsiveImage
        :style="`height: ${isMobile ? 'auto' : '100%'}`"
        :images="[
          {
            url: $t('widgets.checkout.suggestedProducts.backgroundImages[0].url'),
            format: $t('widgets.checkout.suggestedProducts.backgroundImages[0].format'),
            altText: $t('widgets.checkout.suggestedProducts.backgroundImages[0].altText'),
          },
          {
            url: $t('widgets.checkout.suggestedProducts.backgroundImages[1].url'),
            format: $t('widgets.checkout.suggestedProducts.backgroundImages[1].format'),
          },
        ]"
      />
      <div
        class="c-suggestedProducts__productsContainer"
        ref="container"
      >
        <!--Todo: we will need the elements that are commented later-->
        <div class="h-p-10">
          <Typography
            class="-fontBold -lightColor"
            v-text="$t('widgets.checkout.suggestedProducts.title')"
            variant="subtitle1"
          />
          <!-- <Typography
            class="-fontBold -lightColor h-mt-0 h-mb-15"
            v-text="$t('checkout.suggestedProducts.subTitle')"
            variant="body1"
          />-->
          <div class="c-suggestedProducts__productsGrid">
            <Card
              v-for="{id, images, name, slug, description, price} in suggestedProducts"
              :key="id"
              :images="images"
              image-max-height="90px"
              image-min-width="80px"
              :title="name"
              :subtitle="''"
              display="flex"
              image-position="Right"
              rounded
              @click="
                pushToPath(`/item-details/${selectedRestaurantId}/${deliveryType}/${slug}/${id}`)"
            >
              <Typography
                class="-fontBold -muted -opacity h-m-0"
                v-text="description"
                variant="body2"
              />
              <div class="c-suggestedProducts__amount">
                <Typography
                  class="-fontBold -primaryColor"
                  v-text="`${price} €`"
                  variant="body2"
                />
                <!--<Typography
                  class="-fontBold -muted -primaryColor"
                  v-text="`${Number(item.dicountPrice)} €`"
                  variant="body2"
                />-->
              </div>
            </Card>
          </div>
          <!--<Typography
            class="-fontBold -lightColor h-mb-0"
            v-text="$t('checkout.suggestedProducts.bottomTitle')"
            variant="subtitle2"
          />
          <Typography
            class="-fontBold -lightColor h-mt-0"
            v-text="$t('checkout.suggestedProducts.bottomSubTitle')"
            variant="subtitle3"
          />
          <BaseButton
            variant="solid"
            color="tertiary"
            :btn-label="$t('checkout.suggestedProducts.buttonText')"
          />-->
        </div>
      </div>
    </div>
  </BaseContainer>
</template>

<script setup>
import {
  computed, ref, onMounted, onUnmounted, inject,
} from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import Card from '@/components/common/Card/Card.vue';
import ResponsiveImage from '@/components/common/ResponsiveImage.vue';
import BaseContainer from '@/components/grid/BaseContainer.vue';
import Trans from '@/mixins/translation';

const router = useRouter();
const store = useStore();
const isMobile = inject('isMobile');
const container = ref(null);
const height = ref('');
const suggestedProducts = computed(() => store.getters['cart/getSuggestedProducts']);
const selectedRestaurantId = computed(() => store.state.restaurant.selectedRestaurant.id);
const deliveryType = computed(() => store.state.order.orderData.delivery_type);

const resize = () => {
  if (suggestedProducts?.value?.length) {
    height.value = `${container.value.getBoundingClientRect().height}px`;
  }
};

const pushToPath = path => router.push(Trans.i18nRoutePath(path));

onMounted(() => {
  resize();
  window.addEventListener('resize', resize);
});

onUnmounted(() => {
  window.removeEventListener('resize', resize);
});
</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-suggestedProducts {
  @include mx.use-bg-color("secondary");

  position: relative;

  height: v-bind(height);

  &__productsContainer {
    @include mx.d-flex(center, center, true);

    position: absolute;
    top: 50%;
    left: 50%;

    width: 100%;
    padding: 30px 0;

    transform: translate(-50%, -50%);
    text-align: center;
    letter-spacing: 0.58px;
  }

  &__productsGrid {
    display: grid;

    width: 100%;
    height: auto;
    padding-bottom: 20px;

    cursor: pointer;
    grid-template-columns: 100%;
    grid-auto-rows: auto;
    grid-gap: 4px;

    @include mx.bp('tablet-wide') {
      grid-template-columns: 50% 50%;
    }
  }

  &__amount {
    @include mx.d-flex('flex-start', false, false);
  }

  .-opacity {
    opacity: 0.7;
  }

  .-primaryColor {
    @include mx.use-color('primary');
  }

  .-strikeThrough {
    position: relative;

    &:before {
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;

      content: "";
      transform: rotate(-12deg);

      border-color: inherit;
      border-top: 1px solid;
    }
  }

  &::v-deep(.c-baseButton) {
    margin: auto;
  }
}
</style>
