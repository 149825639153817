<template>
  <div
    v-if="isMobile"
    class="c-paymentFooter"
  >
    <SectionContainer
      size="lg"
    >
      <div class="c-paymentFooter__wrapper">
        <Typography
          class="-primary"
          v-text="$t('widgets.cart.totalText')"
          variant="subtitle3"
        />
        <Typography
          class="-fontBook"
          v-text="subTotalNoDiscounted"
          variant="subtitle1"
        />
      </div>
      <BaseButton
        variant="solid"
        color="primary"
        size="medium"
        :btn-label="$t(props.i18nBtnLabel)"
        :to="$i18nRoute({name: routeNamePath })"
      />
    </SectionContainer>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue';
import { useStore } from 'vuex';

import BaseButton from '@/components/common/BaseButton.vue';

const store = useStore();
const { CHECKOUT } = inject('routeNames');
const subTotalNoDiscounted = computed(() => store.getters['cart/getFormattedSubTotalNoDiscount']);
const isMobile = inject('isMobile');

const props = defineProps({
  i18nBtnLabel: {
    type: String,
    default: 'widgets.checkout.bannerTitle',
  },
  routeName: {
    type: String,
    default: '',
  },
});

const routeNamePath = props.routeName ? props.routeName : CHECKOUT;

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-paymentFooter {
  @include mx.use-zeta-index('backdrop');

  position: fixed;
  bottom: 0;

  width: 100%;
  padding: 24px 0;

  background: var(--#{ v.$variable-prefix}bodyColorDt) no-repeat padding-box;
  box-shadow: v.$base-box-shadow;

  &__wrapper {
    @include mx.d-flex("space-between", "center", false);
  }

  &::v-deep(.c-baseButton) {
    width: auto;
  }
}
</style>
