<template>
  <Page :title="$t('widgets.checkout.bannerTitle')">
    <div class="l-checkout">
      <BannerImageAndTitle
        :background="[
          {
            url: $t('widgets.checkout.bannerImg[0].url'),
            format: $t('widgets.checkout.bannerImg[0].format'),
            altText: $t('widgets.checkout.bannerImg[0].altText'),
          },
          {
            url: $t('widgets.checkout.bannerImg[1].url'),
            format: $t('widgets.checkout.bannerImg[1].format'),
          },
        ]"
        :banner-title="$t('widgets.checkout.bannerTitle')"
        :navigate-back="true"
      />

      <SectionContainer
        class="l-checkout__sectionContainer"
        size="md"
      >
        <header class="l-checkout__selectedRestaurant">
          <h1>{{ selectedRestaurant.name }}</h1>
          <p>{{ selectedRestaurant.address }}, {{ selectedRestaurant.city }}</p>
        </header>
        <DeliveryOptionTabs>
          <Cart is-checkout />
        </DeliveryOptionTabs>
      </SectionContainer>

      <!--suggested prods-->
      <SuggestedProducts />

      <!--complete order-->
      <BaseContainer>
        <div class="l-checkout__completeOrderSection">
          <Typography
            class="-fontBold h-mb-0"
            v-text="$t('widgets.checkout.suggestedProducts.completeOrder.title')"
            variant="subtitle2"
          />
          <Typography
            class="h-mb-20"
            v-text="$t('widgets.checkout.suggestedProducts.completeOrder.subTitle')"
            variant="body2"
          />
          <BaseButton
            variant="solid"
            color="primary"
            :btn-label="$t('widgets.checkout.suggestedProducts.completeOrder.buttonText')"
            :to="Trans.i18nRoutePath(`/dashboard/${selectedRestaurantId}`)"
          />
        </div>
      </BaseContainer>
      <ConfirmPayment />

      <PaymentFooter
        v-if="hasItems"
        :route-name="PAYMENT"
        i18n-btn-label="widgets.checkout.suggestedProducts.confirmOrder.buttonText"
      />
    </div>
  </Page>
</template>

<script setup>
import { computed, inject, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { apiServices } from '@/api/services';
import Cart from '@/components/Cart.vue';
import Page from '@/components/Page.vue';
import BannerImageAndTitle from '@/components/banner/BannerImageAndTitle.vue';
import SuggestedProducts from '@/components/checkout/SuggestedProducts.vue';
import BaseButton from '@/components/common/BaseButton.vue';
import DeliveryOptionTabs from '@/components/common/DeliveryOptionTabs.vue';
import BaseContainer from '@/components/grid/BaseContainer.vue';
import ConfirmPayment from '@/components/payment/ConfirmPayment.vue';
import PaymentFooter from '@/components/payment/PaymentFooter.vue';
// TODO: Fix ASAP all import/no-cycle
// eslint-disable-next-line import/no-cycle
import Trans from '@/mixins/translation';

const { HOME, PAYMENT } = inject('routeNames');
const store = useStore();
const router = useRouter();
const cartProducts = computed(() => store.getters['cart/getCartProducts']);
const selectedRestaurantId = computed(() => store.state.restaurant.selectedRestaurant.id);
const selectedRestaurant = computed(() => store.state.restaurant.selectedRestaurant);
const hasItems = computed(() => !!store.state.cart.cart_items.products?.length);

if (hasItems.value && !selectedRestaurantId.value) {
  apiServices.getDefaultRestaurantsList().then(response => {
    const restaurant = response.find(r => r.id === store.state.cart.cart_items.shop_id);

    store.dispatch('restaurant/setSelectedRestaurant', restaurant);
  });
}

onMounted(() => {
  if (!cartProducts.value?.length) {
    router.replace({ name: HOME });
  }
});

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.l-checkout {
  &__sectionContainer {
    padding: 2rem 0;

    &::v-deep(.l-tabs) {
      @include mx.bp('desktop') {
        width: 65%;
      }
    }
  }

  &__suggested {
    position: relative;

    height: 100vh;
  }

  &::v-deep(.l-discoverSpecialities) {
    padding: 0;
  }

  &__completeOrderSection {
    padding: 3rem;
    @include mx.d-flex(center, center, true);
  }

  &__appBanner {
    padding: 3rem 0;
  }

  &__selectedRestaurant {
    margin-bottom: 50px;
  }
}
</style>
